import format from "date-fns/format";
import replaceCurlyQuotes from "./replaceCurlyQuotes";

export enum FTD_LOCATION_TYPE {
  FUNERAL = "Funeral Home",
  BUSINESS = "Business",
  RESIDENCE = "Residence",
  OTHER = "Other",
}

export const v2BaseProdFtdUrl =
  "https://www.ftd.com/occasion/sympathy/funeral-flowers";
export const v2BasePreprodFtdUrl =
  "https://merx.dev.ftd.com/occasion/sympathy/sympathy-flowers";

export interface FtdParameters {
  /**
   * Decedent’s first name. Max length: 20 characters.
   */
  fname?: string;
  /**
   * Decedent’s last name. Max length: 20 characters.
   */
  lname?: string;
  /**
   * Time of the decease’s funeral service. Format: HH:MM (24 hours, e.g. 18:00)
   */
  fstime?: Date;
  /**
   * Date of the decease’s funeral service. Format: MM/DD/YY
   */
  fsdate?: Date;
  /**
   * Address 1. Max length: 45 characters.
   */
  add1?: string;
  /**
   * Address 2. Max length: 45 characters.
   */
  add2?: string;
  /**
   * City. Max length: 30 characters.
   */
  city?: string;
  /**
   * State. Max length: 30 characters.
   */
  state?: string;
  /**
   * Zip code. Max length: 10 characters.
   */
  zip?: string;
  /**
   * Phone number. Max length: 20 characters.
   */
  phone?: string;
  /**
   * Decedent Image URL without HTTP or HTTPS.
   */
  image?: string;
  /**
   * The service provider type. One of: Funeral Home, Business, Residence, Other - all other location type.
   */
  locationType?: keyof typeof FTD_LOCATION_TYPE;
  /**
   * The service provider name.
   */
  locationName?: string;
}

/**
 * @param params [FTD params for V1 or V2](https://gannett-my.sharepoint.com/:f:/r/personal/mmarkova_gannett_com/Documents/FTD?csf=1&web=1&e=vr9M8W)
 * @returns The formatted URL
 */
const getFtdUrl = (params: FtdParameters): string => {
  const {
    fname,
    lname,
    fstime,
    fsdate,
    add1,
    add2,
    city,
    state,
    zip,
    phone,
    image,
    locationType,
    locationName,
  } = params;

  let ftdUrl = "";

  try {
    ftdUrl += "partnerid=Gannett";
    if (locationType) ftdUrl += `&locationType=${locationType}`;
    if (locationName)
      ftdUrl += `&locationName=${replaceCurlyQuotes(locationName)}`;

    // For both V1 and V2
    if (fname) ftdUrl += `&fname=${replaceCurlyQuotes(fname)}`;
    if (lname) ftdUrl += `&lname=${replaceCurlyQuotes(lname)}`;
    if (image) ftdUrl += `&image=${image}?crop=1:1,offset-x0,offset-y0`;
    if (add1) ftdUrl += `&add1=${replaceCurlyQuotes(add1)}`;
    if (add2) ftdUrl += `&add2=${replaceCurlyQuotes(add2)}`;
    if (city) ftdUrl += `&city=${replaceCurlyQuotes(city)}`;
    if (state) ftdUrl += `&state=${replaceCurlyQuotes(state)}`;
    if (zip) ftdUrl += `&zip=${replaceCurlyQuotes(zip)}`;
    if (phone) ftdUrl += `&phone=${replaceCurlyQuotes(phone)}`;
    if (fsdate) {
      if (fstime) {
        ftdUrl += `&fsdate=${format(fsdate, "MM/dd/yyyy")}&fstime=${format(
          fstime,
          "HH:mm"
        )}`;
      } else {
        ftdUrl += `&fsdate=${format(fsdate, "MM/dd/yyyy")}`;
      }
    }

    return encodeURI(`${v2BaseProdFtdUrl}?${ftdUrl}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error generating ftd url");

    return v2BaseProdFtdUrl;
  }
};

export default getFtdUrl;
