import { IImageFragment } from "@/graphql/fragments/__generated__/image.types";
import { IImageOrderFragment } from "@/graphql/fragments/__generated__/imageOrder.types";
import { ILocationFragment } from "@/graphql/fragments/__generated__/location.types";
import { IManageListObituaryFragment } from "@/graphql/fragments/__generated__/manageListObituary.types";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { IObituaryBannerDataFragment } from "@/graphql/fragments/__generated__/obituaryBannerData.types";
import { IObituaryTagFragment } from "@/graphql/fragments/__generated__/obituaryTag.types";
import { IUserCreatedObituaryFragment } from "@/graphql/fragments/__generated__/userCreatedObituary.types";
import logger from "@/logging/client";
import { setUTCTimeToLocal } from "@gannettdigital/community-hub-components";
import getFtdUrl from "./getFtdUrl";
import {
  formatImageURL,
  formatImageURLForOutlink,
  formatTreeImageURL,
} from "./urls";

/**
 * Get the deceased's name from an Obituary. If the obit has an associated Life Story, that will be prioritized. We fall back to historical obituary data.
 * @param obit One of the obituary fragments, that the app supports - `Obituary`, `ObituaryBannerData`, `ManageListObituary`, `UserCreatedObituary`
 * @returns The full name of the deceased as a string
 */
export const getFullName = (
  obit:
    | IObituaryFragment
    | IObituaryBannerDataFragment
    | IManageListObituaryFragment
    | IUserCreatedObituaryFragment
): string => {
  if ("life_stories" in obit && obit.life_stories.length > 0) {
    const { first_name, middle_name, last_name, suffix } = obit.life_stories[0];

    const firstMiddleLast = [first_name, middle_name, last_name]
      .filter(Boolean)
      .join(" ");

    return suffix ? `${firstMiddleLast}, ${suffix}` : firstMiddleLast;
  }

  if ("historical_obituary" in obit && obit.historical_obituary) {
    const { title, first_name, middle_name, last_name } =
      obit.historical_obituary;

    if (title) return title;

    if (!first_name && !last_name) return "";

    return `${first_name} ${middle_name ? `${middle_name} ` : ""}${last_name}`;
  }

  return "";
};

/**
 *
 * @param body
 * @param maxWords
 * @returns
 */
export const shortenObituaryText = (body: string, maxWords: number) => {
  if (maxWords === -1) {
    return body;
  }
  const words = body.split(" ");
  if (maxWords > words.length) {
    return body;
  }
  return `${words.slice(0, maxWords).join(" ")}...`;
};

/**
 * Converts obitury tags to publisher names
 * @param tags Array of `ObituaryTag` fragments
 * @returns An array with the mapped publisher names
 */
export const obituaryTagsToPublishers = (
  tags?: IObituaryTagFragment[]
): string[] => {
  if (!tags) return [];

  const pubs = tags
    .flatMap((pub) => {
      return pub.tag.web_config_filter_tags.map(
        (fTags: { web_config_filter: { name: string } }) =>
          fTags.web_config_filter.name
      );
    })
    .filter(Boolean);

  return pubs;
};

/**
 * Sorts images by finding the index in the imageOrders array.
 * If the image is not included in `imageOrders`, it would be appended at the end
 * @param images An array of `Image` fragments
 * @param imageOrders An array of `ImageOrder` sragment
 * @returns The sorted images with a new order field, based on its index in the `imageOrders` array
 */
export const sortImagePaths = (
  images: IImageFragment[],
  imageOrders: IImageOrderFragment[]
) => {
  if (!images.length) return [];
  if (!imageOrders.length) {
    logger.info(
      `tried sorting images with ids = ${images
        .slice(0, 5)
        .map((i) => i.id)
        .join(", ")}, but no image orders found. Returning unsorted images.`
    );
    return images.map((image, index) => ({ ...image, order: index }));
  }

  const _images = images.map((image) => {
    const _order = imageOrders[0].order.indexOf(image.id);

    return {
      ...image,
      order: _order > -1 ? _order : Infinity,
    };
  });

  return _images.sort((a, b) => a.order - b.order);
};

/**
 * Finds the lead photo and returns the formatted source
 * @param images An array of `Image` fragments
 * @param imageOrders An array of `ImageOrder` sragment
 * @param publicationDomain [optional] The publication domain - is required for absolute outlink URL
 * @returns The image source of the lead photo
 */
export const getLeadPhotoUrl = (
  images: IImageFragment[],
  imageOrders: IImageOrderFragment[],
  publicationDomain: string | undefined
): string | undefined => {
  if (!images.length || !imageOrders.length) return undefined;

  const sorted = sortImagePaths(images, imageOrders);
  const uri = sorted?.[0]?.uri;

  // if we have a publication domain and not empty, we need to build the absolute URL
  if (publicationDomain) {
    return formatImageURLForOutlink(uri, publicationDomain);
  }

  return formatImageURL(uri) || undefined;
};

/**
 * Finds the lead photo and returns the formatted source
 * @param images An array of `Image` fragments
 * @param imageOrders An array of `ImageOrder` sragment
 * @returns The image source of the lead photo
 */
export const getLeadPhotoUrlForTrees = (
  images: IImageFragment[],
  imageOrders: IImageOrderFragment[]
): string | undefined => {
  if (!images.length || !imageOrders.length) return undefined;

  const sorted = sortImagePaths(images, imageOrders);

  return formatTreeImageURL(sorted?.[0]?.uri) || undefined;
};

export interface FtdCardValues {
  ftdUrl: string;
  address1?: string;
  address2?: string;
  name: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

const defaultFtdCardValues = {
  ftdUrl: "",
  address1: "",
  address2: "",
  name: "",
  city: "",
  state: "",
  country: "",
  zip: "",
};

/**
 * Formats the values for the `SendFlowersContent` component. All links redirects to the new (v2) FTD platform.
 * 
 *    **Scenario 1**: *available fhid + upcomming services*:
      - the first element in the array is with the info for the funeral home, associated with the obit
      - the next elements are with the info for the upcomming servicef events
      - having an array with length > 0 opens a popup with the options
 *
 *    **Scenario 2**: *available fhid only*:
      - there is only one element in the array - with the info for the funeral home
      - the option is diplayed in a popup

 *    **Scenario 3**: *upcoming services only*:
      - there are options only with the upcoming services
      - the options are displayed in a popup 
 *
 *    **Scenario 4**: *no fhid or upcoming services*:
      - the returned value would be an empty array => no popup
      - the Send flowers CTA is a simple link, which redirects to FTD 
 * @param obituary The obituary data
 * @param funeralHome The funeral home data
 * @param publicationDomain The publication domain - required for the FTD absolute URL
 * @returns The formatted values for the Send flowers CTA on obit slug page
 */
export const formatFtdCardValues = (
  obituary: IObituaryFragment,
  publicationDomain: string,
  funeralHome?: ILocationFragment
): FtdCardValues[] => {
  const values: FtdCardValues[] = [];

  const fhid =
    obituary?.historical_obituary?.funeralHome?.external_location_id || "";

  // prioritize the obituary home first
  if (fhid && funeralHome) {
    const funeralHomeFtdLink = getFtdUrl({
      fname: obituary?.historical_obituary?.first_name || "",
      lname: obituary?.historical_obituary?.last_name || "",
      image:
        getLeadPhotoUrl(
          obituary?.images,
          obituary?.image_orders,
          publicationDomain
        ) || "",
      state: funeralHome?.state || "",
      zip: funeralHome?.postal_code || "",
      city: funeralHome?.city || "",
      add1: funeralHome?.address || "",
      add2: funeralHome?.address_line_2 || "",
    });

    values.push({
      ...defaultFtdCardValues,
      ftdUrl: funeralHomeFtdLink,
      name: funeralHome?.name || "",
      address1: funeralHome?.address || "",
      address2: funeralHome?.address_line_2 || "",
      country: "United States", // Hard-coded for now
      city: funeralHome?.city || "",
      zip: funeralHome?.postal_code || "",
      state: funeralHome?.state || "",
    });
  }

  // add the services
  if (obituary.services.length > 0) {
    obituary.services.forEach(
      ({
        service_durations: durations,
        country,
        state,
        city,
        zip,
        address1,
        address2,
        name,
      }) => {
        if (
          durations &&
          durations.length > 0 &&
          new Date(durations[0].begin) > new Date()
        ) {
          const beginWithOffset = setUTCTimeToLocal(
            durations[0].begin,
            durations[0].begin_offset
          );

          const ftdUrl = getFtdUrl({
            fname: obituary?.historical_obituary?.first_name,
            lname: obituary?.historical_obituary?.last_name,
            image: getLeadPhotoUrl(
              obituary.images,
              obituary.image_orders,
              publicationDomain
            ),
            ...(beginWithOffset && { fsdate: new Date(beginWithOffset) }),
            ...(beginWithOffset &&
              durations[0].type === "date_time" && {
                fstime: new Date(beginWithOffset),
              }),
            state,
            zip,
            city,
            add1: address1,
            add2: address2,
            locationName: name,
          });

          values.push({
            ftdUrl,
            name,
            address1,
            address2,
            country,
            city,
            zip,
            state,
          });
        }
      }
    );
  }

  return values;
};

/**
 * Counts the number of upcoming service events
 * @param services Services data
 * @returns The number of upcoming servicev events
 */
export const getNumberOfUpcommingServices = (
  services?:
    | IObituaryFragment["services"]
    | IObituaryBannerDataFragment["services"]
) => {
  if (!services) return 0;

  // begin is utc and begin_offset is the offset => parsing the utc date and comparing
  // it to a local date is enough
  try {
    const now = new Date();
    const upcommingServices = services.filter((s) => {
      const durations = s.service_durations;
      return !!durations.find((d) => new Date(d.begin) > now);
    });

    return upcommingServices.length;
  } catch (error) {
    return 0;
  }
};
